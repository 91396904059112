import { IconButton, TableCell, TableRow } from "@mui/material";
import { capitalCase } from "change-case";
import Iconify from "src/components/Iconify";
import ParseDate from "src/components/date";

const DataList = ({ item, rowNumber }) => {
  const { id, product, amount, bid_id, created_at, status } = item;
  return (
    <TableRow key={id}>
      <TableCell>{rowNumber}</TableCell>
      <TableCell>
        <ParseDate date={created_at} />
      </TableCell>
      <TableCell>{product?.name}</TableCell>
      <TableCell>{product?.auction_id}</TableCell>

      <TableCell>{amount}</TableCell>
      <TableCell>{bid_id}</TableCell>

      <TableCell>{capitalCase(status || "")}</TableCell>
    </TableRow>
  );
};

export default DataList;
