import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Report = Loadable(lazy(() => import("src/pages/admin/reports/index")));
const FundReport = Loadable(
  lazy(() => import("src/pages/admin/reports/fund/index"))
);
const BuilderReport = Loadable(
  lazy(() => import("src/pages/admin/reports/builder/index"))
);
const PayoutReport = Loadable(
  lazy(() => import("src/pages/admin/reports/payout/index"))
);

const PointReport = Loadable(
  lazy(() => import("src/pages/admin/reports/point/index"))
);
const SalesReport = Loadable(
  lazy(() => import("src/pages/admin/reports/sales/index"))
);
const JoiningReport = Loadable(
  lazy(() => import("src/pages/admin/reports/joining/index"))
);

const IncomeReport = Loadable(
  lazy(() => import("src/pages/admin/reports/income/index"))
);

const TopEarnersReport = Loadable(
  lazy(() => import("src/pages/admin/reports/top-earners/index"))
);
const Bounty = Loadable(
  lazy(() => import("src/pages/admin/reports/bounty/index"))
);
const Surrender = Loadable(
  lazy(() => import("src/pages/admin/reports/surrender/index"))
);

const Auction = Loadable(
  lazy(() => import("src/pages/admin/reports/auction/index"))
);
const OTC = Loadable(lazy(() => import("src/pages/admin/reports/otc/index")));
const Xorbit = Loadable(
  lazy(() => import("src/pages/admin/reports/xorbit/index"))
);
const Transaction = Loadable(
  lazy(() => import("src/pages/admin/reports/transaction/index"))
);
const AuctionReport = Loadable(
  lazy(() => import("src/pages/admin/reports/auctionReport/index"))
);
const UserStatus = Loadable(
  lazy(() => import("src/pages/admin/reports/userStatus/index"))
);
const reports = [
  {
    path: "report",
    element: <Report />,
    children: [
      {
        element: <Navigate to="builder" />,
        index: true,
      },
      {
        path: "builder",
        element: (
          <BuilderReport
            title="settings.reports.business_builder"
            heading="settings.reports.business_builder"
          />
        ),
      },
      {
        path: "fund/credit",
        element: (
          <FundReport
            title="settings.reports.fund_transfer"
            heading="settings.reports.fund_transfer"
          />
        ),
      },
      {
        path: "joining",
        element: (
          <JoiningReport
            title="settings.reports.joining_report"
            heading="settings.reports.joining_report"
          />
        ),
      },
      {
        path: "joining",
        element: (
          <JoiningReport
            title="settings.reports.joining_report"
            heading="settings.reports.joining_report"
          />
        ),
      },
      {
        path: "income",
        element: (
          <IncomeReport
            title="settings.reports.member_income"
            heading="settings.reports.member_income"
          />
        ),
      },
      {
        path: "payout",
        element: (
          <PayoutReport title="Withdrawal Report" heading="Withdrawal Report" />
        ),
      },
      {
        path: "auction",
        element: <Auction title="LUPA Won Report" heading="LUPA Won Report" />,
      },
      {
        path: "user_status",
        element: (
          <UserStatus title="User Status Report" heading="User Status Report" />
        ),
      },
      {
        path: "auction_report",
        element: <AuctionReport title="LUPA Report" heading="LUPA Report" />,
      },

      {
        path: "point/history",
        element: (
          <PointReport
            title="settings.reports.point_history"
            heading="settings.reports.point_history"
          />
        ),
      },
      {
        path: "earners",
        element: (
          <TopEarnersReport
            title="settings.reports.top_earners"
            heading="settings.reports.top_earners"
          />
        ),
      },
      {
        path: "sales",
        element: (
          <SalesReport
            title="settings.reports.sales"
            heading="settings.reports.sales"
          />
        ),
      },
      {
        path: "bounty",
        element: (
          <Bounty
            title="Bounty Collection Report"
            heading="Bounty Collection Report"
          />
        ),
      },
      {
        path: "transaction",
        element: (
          <Transaction
            title="Transaction Report"
            heading="Transaction Report"
          />
        ),
      },

      {
        path: "xorbit",
        element: <Xorbit title="Xorbit Report" heading="Xorbit Report" />,
      },
      {
        path: "otc",
        element: <OTC title="OTC Report" heading="OTC Report" />,
      },
      {
        path: "surrender",
        element: (
          <Surrender title="Capitulate Report" heading="Capitulate Report" />
        ),
      },
    ],
  },
];

export default reports;
