import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useUsersList = (type = null, isFundTransfer) => {
  const [usersList, setUsersList] = useState([]);
  const fetchData = async () => {
    try {
      const { status, data } = await axiosInstance.post(
        isFundTransfer
          ? "/api/user/username/fund-transfer-autocomplete"
          : "/api/username/autocomplete",
        {
          params: {
            type,
          },
        }
      );
      if (status === 200) {
        setUsersList(data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { usersList, fetchData };
};

export default useUsersList;
