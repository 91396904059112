import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/Iconify";
import Transition from "src/utils/dialog-animation";

const PrivacyPolicy = ({ open, onClose }) => {
  return (
    <div>
      {" "}
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiPaper-root": {
            background:
              "linear-gradient(90deg, rgba(11, 25, 62, 1) 0%, rgba(39, 21, 37, 1) 80%, rgba(56, 19, 21, 1) 100%)",
            color: "#fff", // Optional: Ensure text is readable
            borderRadius: "12px", // Add rounded corners
          },
        }}
      >
        <DialogTitle
          id="category"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Privacy Policy</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <Iconify icon="ic:baseline-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: 800, overflow: "auto" }}>
          {" "}
          <div className="addrexxBox">
            {" "}
            <p>Last updated November 20, 2024</p>
            <br />
            <p>
              Thank you for using xorbit.ai. We are committed to protecting your
              privacy and, for that reason, we have adopted this Privacy Policy
              to explain our data collection, use, and disclosure practices for
              the xorbit.ai services (including its website, and mobile and
              web-based applications, and any other tools, products, or services
              provided by XORBIT.AI that link to or reference this Privacy
              Policy) (collectively, the “Services”). The Services are owned and
              operated by XORBIT, a El Salvador limited liability company (“we”,
              “us” or “our”).
            </p>
            <br />
            <p>
              This Privacy Policy applies to information XORBIT.AI collects
              through the Services, as well as other information provided to us
              online or offline by third parties, when we associate that
              information with customers or users of the Services; however, it
              does not apply to information collected from our employees,
              contractors, or vendors. It also does not apply to information
              that you share with third parties or is collected by third parties
              through the Services (including web traffic or crash analytic
              tools). You acknowledge and agree that XORBIT.AI is not
              responsible for the data collection or use practices of any other
              Services user or third party utilized in providing the Services.
            </p>
            <br />
            <p>This Privacy Policy describes, among other things:</p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>Personal and other information we collect about you;</li>
                <li>How we use your information;</li>
                <li>
                  How we may share your information with third parties; and
                </li>
                <li>
                  Your choices regarding the personal information we collect
                  about you.
                </li>
              </ul>
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>1. Consent</p>
            <br />
            <p>
              By accessing or using the Services, you consent to this Privacy
              Policy. If you do not agree with this Privacy Policy, please do
              not access or use the Services. Information gathered through the
              Services may be transferred, used, and stored in the United States
              or in other countries where our service providers or we are
              located. If you use the Services, you agree to the transfer, use,
              and storage of your Personal Information (as defined below) in
              those countries. The data protection and other laws of the United
              States and other countries might not be as comprehensive as those
              in your country. You agree that all transactions relating to the
              Services or xorbit.ai are deemed to occur in the United States,
              where our servers are located.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              {" "}
              2 Collection of Your Personal and Other Information
            </p>
            <br />
            <p>
              When you register for, or use our Services, we collect Personal
              Information. By “Personal Information” we mean information that
              can identify or reasonably be linked to an individual, such as:
            </p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>Names</li>
                <li>Personal or business addresses</li>
                <li>Email addresses</li>
                <li>Phone numbers</li>
                <li>Social Security numbers</li>
                <li>Government-issued identification numbers; and</li>
                <li>
                  Credit card information (which you submit for payment
                  purposes)
                </li>
              </ul>
            </p>
            <br />
            <p>
              Our Services may integrate with social media platforms, including
              Facebook, Twitter, LinkedIn and YouTube When you connect a social
              media account to our Services, then we may collect information
              about that social media account and share information with that
              social media account as described in the connection process. This
              collected information may include, but is not limited to, your
              name, email address, demographic information from your profile,
              friend lists, postings or other content, and your profile picture.
              You acknowledge and agree that XORBIT.AI is not responsible for
              the data collection or use practices of any such connected social
              media platform. You should read each social media platform’s
              privacy policy before connecting that social media account.
            </p>
            <br />
            <p>
              Certain aspects of the Services may also access certain features
              of your mobile device or web browser, including its location
              services (GPS) and collect information from those features, such
              as your precise location. You consent to this access.
            </p>
            <br />
            <p>
              You may choose not to provide Personal Information or prevent the
              Services from accessing certain features of your mobile device,
              (subject to the controls offered by your mobile device’s operating
              system), but this may prevent you from receiving certain features
              of the Services.
            </p>
            <br />
            <p>
              We also collect non-Personal Information relating to the Services,
              that is, information that does not personally identify an
              individual. The non-Personal Information we collect includes how
              you interact with the Services, information generally collected or
              “logged” by Internet websites or Internet services when accessed
              or used by users, and information about your web browser or device
              accessing or using the Services.
            </p>
            <br />
            <p>Examples of the non-Personal Information we collect are:</p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>Your Internet Protocol (IP address);</li>
                <li>
                  Information about your computer or mobile device, such as its
                  maker or operating system version, and HTTP header
                  information;
                </li>
                <li>
                  The pages of our website that you viewed during a visit;
                </li>
                <li>
                  What information, content or advertisements you view or
                  interact with using the Services;
                </li>
                <li>Language preferences</li>
                <li>
                  The city and state in which you are located (but not your
                  precise geographic location); and
                </li>
                <li>
                  Unique identifiers that are not connected and cannot
                  reasonably be connected to your identity.
                </li>
              </ul>
            </p>
            <br />
            <p>
              Finally, our Services may also collect or retain information you
              provide to vendors or other third parties regarding products or
              services in which you’ve expressed an interest using the Services,
              such as your credit score, credit rating, or other demographic
              information.
            </p>
            <br />
            <p>
              If we associate any non-Personal Information with information that
              personally identifies you, then we will treat it as Personal
              Information. We may also aggregate Personal Information and
              non-Personal Information in a manner such that the end-product
              does not personally identify you or any other user of xorbit.ai,
              for example, by using Personal Information to calculate the
              percentage of our users who have a particular telephone area code
              and/or zip code. As discussed in more detail below, we sometimes
              use cookies and other automatic information gathering technologies
              to gather non-Personal Information.
            </p>
            <br />
            <p>
              Information collected by the Services may be collected by us or
              one of our service providers acting on our behalf, but in either
              case, this Privacy Policy will govern the collection, use, and
              sharing of the information.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>3. Use of Your Information</p>
            <br />
            <p>We may use the information we collect to:</p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>
                  Connect you with vendors who will provide you with information
                  about products or services in which you’ve expressed an
                  interest using the Services;
                </li>
                <li>
                  Assist us in providing, maintaining, and protecting the
                  Services;
                </li>
                <li>
                  Set up, maintain, and protect accounts to use the Services;
                </li>
                <li>Improve our online operations;</li>
                <li>Process transactions;</li>
                <li>Provide customer service;</li>
                <li>
                  Communicate with you, such as provide you with account- or
                  transaction—related communications, or other newsletters, RSS
                  feeds, and/or other communications relating to the Services;
                </li>
                <li>
                  Send or display offers and other content that is customized to
                  your interests or preferences, including your Service
                  activity;
                </li>
                <li>
                  Perform research and analysis aimed at improving our products
                  and services and developing new products or services; and
                </li>
                <li>
                  {" "}
                  Manage and maintain the systems that provide the Services.
                </li>
              </ul>
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              4. Disclosure of Your Information
            </p>
            <br />
            <p>
              We may disclose your Personal Information to third parties as
              described below. We may disclose Personal Information to provide
              the Services, or when you authorize or instruct us to do so, for
              example when you use the Services to submit content or profile
              information. Some aspects of the Services are a social experience,
              so we may disclose your interactions with the Services to social
              media platforms, as described in Section 2, above. We may also
              disclose Personal Information and non-Personal Information to
              companies, agents, contractors, service providers, or others
              engaged to perform functions on our behalf (such as processing of
              payments, provision of data storage, hosting of our website,
              marketing of our products and services, conducting audits, sending
              e-mail newsletters, and performing web analytics). We may license
              third party software to include in or use with the Services, in
              which case we may disclose Personal Information and/or
              Non-Personal Information to the licensor.
            </p>
            <br />
            <p>
              We may partner with advertisers or other affiliates to provide you
              with special offers, or to advertise products or services to you.
              For example, if you request information about a particular Crypto
              product or service, the Personal Information you provide is shared
              with the relevant third parties necessary to fulfill your request.
              We may share this Personal Information with providers of Crypto
              products and services, as well as third-party finder and lead
              aggregator companies who will pass along your information to help
              fulfill your request. By entering your Personal Information and
              clicking on “Submit” or any similar button, you are authorizing
              the sharing of your inquiry and your Personal Information with
              other businesses who provide, market, or find Crypto products or
              services that match your inquiry. If you redeem or respond to an
              offer, we may provide your Personal Information to the advertising
              partner, including your name, email address, gender, and year of
              birth. If you answer questions or fill out surveys from an
              advertiser, we may share information with that advertiser. The
              advertising partner’s privacy policy will govern their use of your
              information, which may include marketing of other products or
              services to you. You should read each advertiser’s or affiliate’s
              privacy policy before providing information to that advertiser or
              affiliate. For your convenience, a non-exhaustive list of the
              advertisers and affiliates with whom we may share your
              information, including their respective privacy policies, is
              available{" "}
              <a
                target="_blank"
                href="https://www.banks.com/partners/"
                style={{ color: "green" }}
              >
                here
              </a>
              . You should refer to this link regularly, as we may add or remove
              advertisers or affiliates at any time, in our sole discretion.
            </p>
            <br />
            <p>
              We may also disclose your Personal Information to third parties
              when we believe, in good faith and in our sole discretion, that
              such disclosure is reasonably necessary to (a) enforce or apply
              the terms and conditions of the Services, including investigation
              of potential violations thereof, (b) comply with legal or
              regulatory requirements or an enforceable governmental request,
              (c) protect the rights, property or safety of us, our users or
              other third parties, (d) prevent a crime or protect national
              security, or (e) detect, prevent or otherwise address fraud,
              security or technical issues.
            </p>
            <br />
            <p>
              Finally, we reserve the right to transfer information (including
              your Personal Information) to a third party in the event of a
              sale, merger, or transfer of all or substantially all of the
              assets of our company relating to the Services, or in the unlikely
              event of a bankruptcy, liquidation, or receivership of our
              business. We will use commercially reasonable efforts to notify
              you of such transfer, for example via email or by posting notice
              on our website.
            </p>
            <br />
            <p>
              Lastly, we may also disclose (including by selling) non-Personal
              Information, aggregated with information about our other users, to
              our clients, business partners, merchants, advertisers, investors,
              potential buyers and other third parties if we deem such
              disclosure, in our sole discretion, to have sound business reasons
              or justifications.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              5. Cookies and Automatic Information Gathering Technologies
            </p>
            <br />
            <p>
              Every time you use the Services (e.g., access a Service Webpage,
              navigate to a specific tool within the Service), we collect
              non-Personal Information (discussed above in Section 2) regarding
              that use. For example, to improve our Services, we collect how,
              when, and which parts of the Services or its features you use,
              which social media platforms you connect to the Services, and
              when, how, and what you post to the social media platforms through
              the Service. Also, we may use your device’s unique identifier
              (UDID), media access control address (MAC Address), or other
              unique identifiers (including identifiers we create) to assist us
              in collecting and analyzing this data.
            </p>
            <br />
            <p>
              To assist us in collecting and storing this non-Personal
              Information, we may employ a variety of technologies, including
              “Cookies,” local browser storage, and “Web Beacons.” A “Cookie” is
              a small amount of data a website operator, or a third party whose
              content is embedded in that website, may store in your web browser
              and that the website operator or, as applicable, the third party,
              can access when you visit the website. A Cookie may also refer to
              web-browser-based storage provided by Adobe’s Flash plugin (a
              “Flash Cookie”). A “Web Beacon” is a small, usually-transparent
              image placed on a web page that allows the operator of that image,
              which may be the operator of the website you visit or a third
              party, to read or write a Cookie.
            </p>
            <br />
            <p>
              Your operating system and web browser may allow you to erase
              information stored in Cookies, Flash Cookies, and local browser
              storage. But if you do so, you may be forced to login to the
              Services again and you may lose some preferences or settings. You
              may also be able to set your browser to refuse all website storage
              or to indicate when it is permitted, but some features of our
              Services may not function properly without it. We may use Cookies
              to keep you logged in, save your preferences for the Services, to
              collect information about how you use our Services, to help us
              display content that is customized to your interests and
              preferences, and to improve your experience using the Services.
            </p>
            <br />
            <p>
              More information about managing Cookies is available{" "}
              target="_blank"
              <a href="https://allaboutcookies.org/" style={{ color: "green" }}>
                here
              </a>{" "}
              . Cookie management tools provided by your browser may not affect
              Flash Cookies. More information about managing Flash Cookies is
              available{" "}
              <a
                target="_blank"
                href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118"
                style={{ color: "green" }}
              >
                here
              </a>
              . To learn how to manage privacy and storage settings for your
              local browser storage, please refer to the end user documentation
              for your browser.
            </p>
            <br />
            <p>
              To help us collect this information, we use third-party software
              and services. These third parties’ collection and use of
              non-Personal Information are subject to their own privacy
              policies, which you can read here:
            </p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://policies.google.com/privacy?hl=en"
                  >
                    Google Analytics and Google Tag Manager
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://www.optimizely.com/legal/privacy-notice/"
                  >
                    Optimizely
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://www.hotjar.com/privacy/"
                  >
                    Hotjar
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://getcake.com/privacy-policy/"
                  >
                    Cake
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://www.intuit.com/privacy/statement/"
                  >
                    Mailchimp
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://www.crazyegg.com/privacy"
                  >
                    Crazy Egg
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    style={{ color: "green" }}
                    target="_blank"
                    href="https://www.smarty.com/legal/privacy-policy"
                  >
                    SmartyStreets{" "}
                  </a>
                </li>
              </ul>
            </p>
            <br />
            <p>
              We also work with service providers and third-party advertising
              networks who help us collect non-Personal Information through
              cookies in order to deliver Interest-Based Advertising (IBA) to
              our users. IBA ads are tailored specifically to your likes, based
              on general categories in which you’ve shown an interest. You are
              often able to learn more about IBA and opt out of receiving these
              types of ads within the ads themselves when you see them. For more
              information on how you can opt-out of IBA, please see the
              “Transparency and Choice” section below.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              6. Transparency and Choice; Do Not Track Signals
            </p>
            <br />
            <p>
              You may request access to your Personal Information by sending an
              email to support@xorbit.ai . We will try to locate and provide you
              with your Personal Information and give you the opportunity to
              correct this data, if it is inaccurate, or to delete it, at your
              request. But, in either case, we may need to retain it for legal
              reasons or for legitimate business purposes. You may also remove
              any content that you post to the Services using the deletion or
              removal options within the Service. However, we (and you) are not
              able to control information that you have already shared with
              other users or made available to third parties through the
              Services.
            </p>
            <br />
            <p>
              If you need further assistance with removing any content you
              posted through the Services, you can email us at{" "}
              <a
                href="mailto:support@xorbit.ai
"
              >
                support@xorbit.ai{" "}
              </a>
              . Removal of your posted content may not ensure complete or
              comprehensive removal from our computer systems.
            </p>
            <br />
            <p>
              We ask individual users to identify themselves and the information
              requested to be accessed, corrected, or removed before processing
              such requests, and we may decline to process requests that are
              unreasonably repetitive or systematic, require disproportionate
              technical effort, jeopardize the privacy of others, would be
              extremely impractical (for instance, requests concerning
              information residing on backups), or relate to information that is
              not associated with your Personal Information. In any case, where
              we provide information access and correction, we perform this
              service free of charge, except if doing so would require a
              disproportionate effort.
            </p>
            <br />
            <p>
              Please be aware that if you request us to delete your Personal
              Information, you may not be able to continue to use the Services.
              Also, even if you request that we delete your Personal
              Information, we may need to retain certain information for a
              limited period of time to satisfy our legal, audit and/or dispute
              resolution requirements.
            </p>
            <br />
            <p>
              We may use third-party service providers that collect information
              for interest-based advertising purposes (advertisements that are
              tailored to your likely interests, based on categories in which
              you have shown an interest). To learn more about these third
              parties and the choices they offer users, please visit the Network
              Advertising Initiative’s{" "}
              <a
                style={{ color: "green" }}
                target="_blank"
                href="https://optout.networkadvertising.org/?c=1#!%2F"
              >
                choices page
              </a>{" "}
              or the Digital Advertising Alliance’s{" "}
              <a
                style={{ color: "green" }}
                target="_blank"
                href="https://optout.aboutads.info/?c=2&lang=EN"
              >
                choices page
              </a>
              . If you are reading this Privacy Policy from a mobile device, you
              can learn more about the DAA’s mobile choices program{" "}
              <a
                style={{ color: "green" }}
                target="_blank"
                href="https://youradchoices.com/appchoices"
              >
                here
              </a>
              .
            </p>
            <br />
            <p>
              At this time, xorbit.ai does not respond to “do not track” signals
              that may be sent from your browser.
            </p>
            <br />
            <p>
              You can opt out of receiving marketing e-mails from us by clicking
              on the “unsubscribe” link in the e-mails. Please note that it may
              take up to ten (10) business days for you opt-out request to be
              processed. Also, even if you opt out of marketing e-mails, we may
              continue to send you certain account-related e-mails, such as
              notices about your account and confirmations of transactions you
              have requested.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>7. Children</p>
            <br />
            <p>
              The Services are not intended for users under 18 years of age. We
              do not knowingly collect Personal Information from users under 18
              years of age. We do not authorize users under 18 years of age to
              use the Services.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>8. Information Security</p>
            <br />
            <p>
              We utilize reasonable information security measures to safeguard
              your Personal Information against unauthorized access,
              modification, or destruction. For example, we utilize Secure
              Socket Layer (SSL), Transport Layer Security (TLS), or similar
              encryption technology when sensitive data is transmitted over the
              Internet, and use firewalls to help prevent external access into
              our network. However, no data transmission over the Internet and
              no method of data storage can be guaranteed to be 100% secure.
              Therefore, while we strive to use commercially acceptable means to
              protect your Personal Information, we cannot guarantee its
              security.
            </p>
            <br />
            <p>
              We restrict access to Personal Information to our employees,
              contractors, and agents who need to know that information in order
              to operate, develop, improve or support our Services. If we share
              Personal Information with service providers, we require that they
              also use reasonable information security measures to safeguard
              your Personal Information, and only use your Personal Information
              for the purposes for which we share it with them.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              9. Your EL SALVADOR Privacy Rights
            </p>
            <br />
            <p>
              A El Salvador resident who has provided Personal Information to a
              business with whom he/she has established a business relationship
              for personal, family, or household purposes (“El Salvador
              Customer”) is entitled to request information about whether the
              business has disclosed Personal Information to any third parties
              for the third parties’ direct marketing purposes. In general, if
              the business has made such a disclosure of Personal Information,
              upon receipt of a request by a El Salvador Customer, the business
              is required to provide a list of all third parties to whom
              Personal Information was disclosed in the preceding calendar year,
              as well as a list of the categories of Personal Information that
              were disclosed.
            </p>
            <br />
            <p>
              However, under the law, a business is not required to provide the
              above-described lists if the business adopts and discloses to the
              public (in its privacy policy) a policy of not disclosing a
              customer’s Personal Information to third parties for their direct
              marketing purposes unless the customer first affirmatively agrees
              to the disclosure, as long as the business maintains and discloses
              this policy. Rather, the business may comply with the law by
              notifying the customer of his or her right to prevent disclosure
              of Personal Information to third parties for direct marketing
              purposes and providing a cost free means to exercise that right.
              To prevent disclosure of your Personal Information for use in
              direct marketing by a third party for its own purposes, do not opt
              in to or authorize such use when you provide Personal Information
              through the Services. Please note that whenever you allow your
              Personal Information to be shared with a third party to
              communicate with you, your information will be subject to that
              third party’s privacy policy. If you later decide that you do not
              want that third party to use your information, you will need to
              contact the third party directly. You should always review the
              privacy policy of any party that collects your information to
              determine how that entity will handle your information.
            </p>
            <br />
            <p>
              El Salvador Customers may request further information about our
              compliance with El Salvador’s privacy law by emailing
              support@xorbit.ai Please note that we are only required to respond
              to one request per customer each year, and we are not required to
              respond to requests made by means other than through this e-mail
              address.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>10.Third Party Websites</p>
            <br />
            <p>
              Please note that the Services may link or integrate with
              third-party sites, services or apps. We are not responsible for
              the privacy or security policies or practices or the content of
              such third parties. Accordingly, we encourage you to review the
              privacy and security policies and terms of service of those third
              parties so that you understand how those websites collect, use,
              share and protect your information.
            </p>
            <p style={{ fontWeight: "bold" }}>11.Changes to this Policy</p>
            <br />
            <p>
              We may modify or update this Privacy Policy periodically with or
              without prior notice by posting the updated policy on this page.
              You can always check the “Last Updated” date at the top of this
              document to see when the Privacy Policy was last changed. If we
              make any material changes to this Privacy Policy, we will notify
              you by reasonable means, which may be by e-mail or posting a
              notice of the changes on our website prior to the changes becoming
              effective. We encourage you to check this Privacy Policy from time
              to time. IF YOU DO NOT AGREE TO CHANGES TO THIS PRIVACY POLICY,
              YOU MUST STOP USING THE SERVICES AFTER THE EFFECTIVE DATE OF SUCH
              CHANGES (WHICH IS THE “LAST UPDATED” DATE OF THIS PRIVACY POLICY).
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>12.Questions</p>
            <br />
            <p>
              To ask questions about our Privacy Policy or to lodge a complaint,
              contact us at: XORBIT.AI, Los Verde, 101 Avenida Norte #517, San
              Salvador, El Salvador Email: support@xorbit.a
            </p>
          </div>
        </DialogContent>
      </Dialog>{" "}
    </div>
  );
};

export default PrivacyPolicy;
