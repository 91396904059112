import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Button } from "react-bootstrap";
import Transition from "src/utils/dialog-animation";
import axios from "axios";
import { setSession } from "src/utils/jwt";
import { useTranslation } from "react-i18next";
import { HOST_API } from "src/config";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH } from "src/routes/paths";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useSetPlan from "../auth/Login/components/MainSection/login/hooks/use-set-plan";
import ConfirmPopUP from "./confirmPopUP";

const SignInModal = ({ isOpen, setIsSignInOpen }) => {
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const handleClosePopup = () => {
    setOpenPopup(false);
    setFormData({ email: "", password: "" });
  };

  const closeModal = () => {
    setIsSignInOpen(false);
    setFormData({});
    setErrors({});
  };
  const { i18n } = useTranslation();
  const { getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleGoClick = (e) => {
    e.preventDefault();
    if (formData.email.trim()) {
      setIsFirstSubmit(true);
      setErrors({});
      setError({});
    } else {
      setErrors({ email: "Email Address is required" });
    }
  };
  const [isEmailNotVerified, setIsEmailNotVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const setPlan = useSetPlan();
  const handleLogin = async (e) => {
    setError({});
    e.preventDefault();
    const newErrors = {};
    if (!formData.email.trim()) newErrors.email = "Email Address is required";
    if (!formData.password.trim()) newErrors.password = "Password is required";

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true); // Start loading
      const reqData = new FormData();
      Object.entries(formData).forEach(([k, v]) => reqData.append(k, v));

      try {
        const { status, data, email_verified } = await axios.post(
          `${HOST_API}/api/login`,
          reqData,
          {
            headers: {
              "Accept-Language": i18n.language,
            },
          }
        );

        if (status === 200) {
          const { access_token, user, secret, menu_lists, package_status } =
            data;

          if (!secret) {
            localStorage.setItem("menu", JSON.stringify(menu_lists));
            localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
            localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
            localStorage.setItem("package_status", Boolean(package_status));
            setSession(access_token);
            window.location = "/user/dashboard";
            getUser();
            setFormData({ email: "", password: "" });
            setErrors({});
            setError({});
            setLoading(false);
            setPlan("BINARY");
            closeModal(); // Close the modal
          } else {
            setErrors({ apiError: secret });
            setError({ apiError: secret });
          }
        }
      } catch (err) {
        setIsEmailNotVerified(err.response?.data?.email_verified);
        if (err.response.data.errors) {
          setErrors({
            apiError: err.response?.data?.errors.email[0],
          });
        } else {
          setError({
            apiError: err.response?.data?.message || "Login failed",
          });
        }
      } finally {
        setLoading(false); // Stop loading after the call finishes
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };
  const [loadingEmail, setLoadingEmail] = useState(false);

  const EmailReVerify = async () => {
    const reqData = new FormData();
    reqData.append("email", formData.email);
    reqData.append("password", formData.password);
    try {
      setLoadingEmail(true);
      const { data, status, message } = await axiosInstance.post(
        "api/re-verify",
        reqData
      );

      if (status === 200) {
        setLoadingEmail(false);
        closeModal();
        setOpenPopup(true);
        setMessage(data.message);
        // enqueueSnackbar(data.message, { variant: "success" });
      }
    } catch (err) {
      setLoadingEmail(false);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="sign-in-dialog"
      >
        <Box className="modal-overlay signInUp" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <Typography sx={{ textTransform: "uppercase", color: "#fff" }}>
              Sign In
            </Typography>

            <form onSubmit={isFirstSubmit ? handleLogin : handleGoClick}>
              {/* Account ID Input */}
              <Box sx={{ mt: 4 }}>
                <input
                  type="text"
                  name="email"
                  placeholder="eMail Address or Username"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={loading}
                />
                {errors.apiError ? (
                  <p style={{ color: "red" }}>{errors.apiError}</p>
                ) : (
                  <p style={{ color: "red" }}>{errors.email}</p>
                )}
              </Box>

              {/* Password Input */}
              {isFirstSubmit && (
                <Box sx={{ mt: 2 }}>
                  <Input
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Passkey"
                    value={formData.password}
                    onChange={handleChange}
                    disabled={loading}
                    sx={{
                      border: "none", // Remove border
                      "&:before, &:after": {
                        display: "none", // Remove the underline
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && (
                    <p style={{ color: "red" }}>{errors.password}</p>
                  )}{" "}
                </Box>
              )}
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {error.apiError && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      mt: 2,
                    }}
                  >
                    <p style={{ color: "red", margin: 0 }}>{error.apiError}</p>
                    {isEmailNotVerified === 0 && (
                      <Button
                        disabled={loadingEmail}
                        className="butonstyleEmail"
                        size="small"
                        onClick={EmailReVerify}
                        style={{ textTransform: "none" }}
                      >
                        {loadingEmail ? (
                          <CircularProgress size={15} sx={{ color: "white" }} />
                        ) : (
                          "Resend eMail"
                        )}
                      </Button>
                    )}
                  </Box>
                )}
              </div>

              {/* Submit button */}
              <Box sx={{ mt: 2 }}>
                <Button
                  className="butonstyle1"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={15} sx={{ color: "white" }} />
                  ) : isFirstSubmit ? (
                    "Login"
                  ) : (
                    "Go"
                  )}
                </Button>
              </Box>

              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Link
                  sx={{ fontSize: "14px", cursor: "pointer" }}
                  style={{ color: "#958262" }}
                  component={RouterLink}
                  variant="subtitle2"
                  to={PATH_AUTH.resetPassword}
                  gutterBottom
                >
                  Forgot Passkey ?
                </Link>
                <Link
                  sx={{ fontSize: "14px", cursor: "pointer" }}
                  style={{ color: "#958262" }}
                  component={RouterLink}
                  variant="subtitle2"
                  to={PATH_AUTH.resetAccountId}
                  gutterBottom
                >
                  Forgot Account ID ?
                </Link>
              </Box>
            </form>
          </div>
        </Box>
      </Dialog>

      <Dialog
        className="registerAlertBox"
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="add-article-category"
        sx={{ backgroundColor: "transparent" }}
      >
        <ConfirmPopUP message={message} />
      </Dialog>
    </>
  );
};

export default SignInModal;
