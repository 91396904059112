import { useEffect } from "react";
import ParticleSection from "./particles";
const { Box } = require("@mui/material");

const LandingBackground = ({ children, isUser }) => {
  useEffect(() => {
    if (isUser) {
      document.body.classList.add("user-body");
    } else {
      document.body.classList.remove("user-body");
    }
  }, [isUser]);

  return (
    <Box className="landingBg" sx={{ display: "flex", flexWrap: "wrap" }}>
      <ParticleSection />
      {children}
    </Box>
  );
};

export default LandingBackground;
