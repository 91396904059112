import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import Translate from "src/components/translate";
import useAuth from "src/hooks/useAuth";
import useErrors from "src/hooks/useErrors";

import axiosInstance from "src/utils/axios";
import * as Yup from "yup";

const AmountBidding = ({ reload }) => {
  const handleErrors = useErrors();
  const { enqueueSnackbar } = useSnackbar();

  const Validator = Yup.object().shape({
    amount: Yup.string()
      .typeError("Amount is required")
      .required("Amount is required"),
  });
  const defaultValues = {
    amount: "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(Validator),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (reqdata) => {
    let data = new FormData();
    data.append("amount", reqdata.amount);
    try {
      const { status, data: resData } = await axiosInstance({
        method: "post",
        url: "/api/user/auction-bid",
        data: data,
      });
      if (status === 200) {
        reset();
        reload();
        enqueueSnackbar(resData.message);
      }
    } catch (err) {
      if (err.errors) {
        Object.entries(err.errors).forEach(([k, v]) =>
          setError(k, { message: v[0] })
        );
      } else {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField
            size="small"
            name="amount"
            label={"Enter Your Quote"}
            type="number"
            onKeyDown={(e) => {
              const value = e.target.value;
              if (
                value.includes(".") &&
                value.split(".")[1].length >= 2 &&
                !["Backspace", "ArrowLeft", "ArrowRight"].includes(e.key)
              ) {
                e.preventDefault(); // Prevent typing after 2 decimal places
              }
            }}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            name="payout-wallet"
          >
            Submit{" "}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
};

export default AmountBidding;
